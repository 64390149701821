import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import candidatesData from '../data/candidates.json';
import Modal from '../components/Modal'; // Import the Modal component
import Accordion from '../components/Accordion'; // Import the Accordion component
import { useAuth } from '../useAuth';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase-config';

function compareNumbers(a, b) {
  return b.Score - a.Score;
}
const CandidateListPage = () => {
  const navigate = useNavigate(); // Hook for navigation
  const { jobID, tenantID } = useParams();
  // const candidates = candidatesData[jobID].sort(compareNumbers) || [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [candidates, setCandidates] = useState([]); 
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  
  const { currentUser } = useAuth();

  useEffect(() => {
    fetch(`https://interview-412415.ue.r.appspot.com/api/v1/tenants/${tenantID}/jobpostings/${jobID}/applications`, {
      method: "GET",
      headers: {
        "X-RapidAPI-Key": "your-api-key",
        "X-RapidAPI-Host": "jokes-by-api-ninjas.p.rapidapi.com",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCandidates(data.user? data.user.sort(compareNumbers): []);
        console.log(data);
      })
      .catch((error) => console.log(error));
  }, [tenantID, jobID]);


  console.log(`currentUser ${JSON.stringify(currentUser)}`)
  const signout = async (event) => {
    event.preventDefault();
    console.log(`event: ${event}`)
    signOut(auth).then((i) => {
    // Sign-out successful.
  console.log(`Sign-out successful. ${i} \ncurrentUser ${JSON.stringify(currentUser)}`)
  }).catch((error) => {
    // An error happened.
  console.log(`Sign-out failed. currentUser ${JSON.stringify(currentUser)}, ${error}`)
  });
}
  if (!currentUser) {
    return <Navigate to="/signin" replace />;
  }

    // Function to handle navigation back to the job listings page
    const handleBackToJobs = () => {
      navigate('/job-postings'); // Adjust the path as necessary based on your routing setup
    };
  
  if (!candidates) {
    return <div>Candidate not found.</div>;
  }
    // Function to open the modal for a specific candidate
    const handleOpenStatusModal = (candidateId) => {
      setCurrentCandidate(candidates.find(candidate => candidate.uuid === candidateId));
      setIsStatusModalOpen(true);
    };
  
    // Function to update candidate status (simplified, update your state accordingly) TODO: call candidate list from API using fetch
    const handleUpdateCandidateStatus = (candidateId, newStatus) => {
      setCandidates(candidates.map(candidate => 
        candidate.uuid === candidateId ? { ...candidate, ApplicationStatus: newStatus } : candidate
      ));
      fetch(`https://interview-412415.ue.r.appspot.com/api/v1/tenants/${tenantID}/jobpostings/${jobID}/applications/${candidateId}`, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentCandidate),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(`Status updated successfully: ${JSON.stringify(data.user)}`);
          setIsStatusModalOpen(false); // Assuming you wish to close the modal after updating
          alert('Status updated successfully.');
        })
        .catch((error) => console.log(error));
    };
    
  // Function to render candidate details for the modal
  const renderCandidateDetails = (candidate) => (
    <div className="space-y-4">
      <Accordion title="Contact Information">
        <p><strong>Email:</strong> {candidate.ContactInformation.Email}</p>
        <p><strong>Phone:</strong> {candidate.ContactInformation.Phone}</p>
        <p><strong>Address:</strong> {candidate.ContactInformation.Address}<strong> Ville:</strong> {candidate.ContactInformation.City}<strong> Pays:</strong> {candidate.ContactInformation.Country}</p>
      </Accordion>
  
      <Accordion title="Documents">
        <div><strong>Resume:</strong> <a href={candidate.Resume} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Download Resume</a></div>
        <div><strong>Cover Letter:</strong> {candidate.CoverLetter?<a href={candidate.CoverLetter} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Download Cover Letter</a>:""}</div>
        {candidate.AdditionalDocuments? candidate.AdditionalDocuments.map((doc, index) => (
          <div key={index}><strong>Additional Document {index + 1}:</strong> <a href={doc} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Download</a></div>
        )):""}
      </Accordion>
  
      <Accordion title="Education">
        {candidate.Education.map((edu, index) => (
          <p key={index}><strong>{edu.Degree} in {edu.FieldOfStudy}</strong>, {edu.Institution} ({edu.GraduationYear})</p>
        ))}
      </Accordion>
  
      <Accordion title="Work Experience">
        {candidate.WorkExperience?candidate.WorkExperience.map((exp, index) => (
          <div key={index}>
            <p><strong>{exp.Role}</strong> at {exp.CompanyName}, from {exp.StartDate} to {exp.EndDate}</p>
            <p>{exp.Responsibilities}</p>
          </div>
        )): ""}
      </Accordion>
  
      <Accordion title="Skills">
        <p>{candidate.Skills?candidate.Skills.join(', '):""}</p>
      </Accordion>
  
      <Accordion title="Certifications">
        {candidate.Certifications?candidate.Certifications.map((cert, index) => (
          <p key={index}>{cert.CertificationName} from {cert.IssuingOrganization}, obtained on {cert.DateObtained}. Expires on {cert.ExpiryDate}.</p>
        )):""}
      </Accordion>
  
      <Accordion title="Languages">
        {candidate.Languages?candidate.Languages.map((lang, index) => (
          <p key={index}>{lang.Language} - {lang.Proficiency}</p>
        )):""}
      </Accordion>
  
      <Accordion title="References">
        {candidate.References?candidate.References.map((ref, index) => (
          <p key={index}><strong>{ref.RefereeName}</strong>, {ref.Relation}, {ref.ContactInformation}</p>
        )):""}
      </Accordion>
  
      <Accordion title="Personal Statement">
        <p>{candidate.PersonalStatement}</p>
      </Accordion>
  
      <Accordion title="Social Media Profiles">
      
        <p>{candidate.SocialMediaProfiles?<a href={candidate.SocialMediaProfiles.LinkedIn} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">LinkedIn</a>:""}</p>
        <p>{candidate.SocialMediaProfiles?<a href={candidate.SocialMediaProfiles.Behance} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Behance</a>:""}</p>
      </Accordion>
  
      <Accordion title="Portfolio">
        <p><a href={candidate.Portfolio} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">View Portfolio</a></p>
      </Accordion>
  
      <Accordion title="Volunteer Experience">
        <p>{candidate.VolunteerExperience}</p>
      </Accordion>
  
      <Accordion title="Availability">
        <p>{candidate.Availability}</p>
      </Accordion>
    </div>
  );
  
  
  return (
    <div className="bg-african-sand p-6 rounded-lg shadow-md">
      {/* Button to return to the job listing page */}
      <button
        onClick={signout}
        className="absolute top-0 right-0 m-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Sign Out
      </button>
      <button
        onClick={handleBackToJobs}
        className="absolute top-0 right-100 m-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Back to Jobs
      </button>
      <h1 className="text-3xl font-bold text-center mb-6 text-blue-800">Candidates for Job ID: {jobID}</h1>
      {candidates.length > 0 ? (
        <div className="space-y-4">
          {candidates.map(candidate => (
            <div key={candidate.uuid} className="bg-blue-50 shadow-md rounded-lg p-4">
              <h2 className="text-xl font-bold text-blue-700" onClick={toggleModal}>{candidate.FullName}</h2>
      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        {renderCandidateDetails(candidate)}
      </Modal>
              <p className="text-blue-600">{candidate.ContactInformation.Email}</p>
              <p className="text-gray-600">Location: {candidate.ContactInformation.Address}</p>
              <div><strong>Resume:</strong> <a href={candidate.Resume} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Download Resume</a></div>
              <p className="text-gray-600">Classement: {candidate.Score}</p>
              <p className="text-gray-600">Explication: {candidate.ScoreReason}</p>
              <p className={`text-gray-600 ${candidate.ApplicationStatus === 'Offered' ? 'text-green-600' : ''}`}>  Statut: {candidate.ApplicationStatus}{  }
              <button onClick={() => handleOpenStatusModal(candidate.uuid)} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
          Changer Statut
        </button></p>
        <Modal isOpen={isStatusModalOpen} onClose={() => setIsStatusModalOpen(false)} title="Changer Le Statut Du Candidat">
  <form onSubmit={(e) => {
   e.preventDefault();
   const newStatus = e.target.status.value; // Assuming the select has a name attribute of 'status'
   
   // Ensure `currentCandidate` is set up to track the candidate being edited
   if (currentCandidate) {
     handleUpdateCandidateStatus(currentCandidate.uuid, newStatus);
   }

   setSelectedStatus(''); // Reset the selected status if needed
  }}>
    <select 
      name="status" 
      className="border-gray-300 rounded" 
      value={selectedStatus} 
      onChange={(e) => setSelectedStatus(e.target.value)}
    >
      <option value="">Choisir Status</option>
      <option value="Submission">Soumission de la candidature</option>
      <option value="Evaluation">Soumission de tests d'evaluation</option>
      <option value="Interview">Entretient</option>
      <option value="Rejecter">Rejeter</option>
      <option value="Offer">Offre d'emploi</option>
      <option value="Accepted">Offre Accepter</option>
      {/* Add other statuses as needed */}
    </select>
    {selectedStatus && (
      <p className="text-red-500">Status: {selectedStatus}. Veillez confirmer.</p>
    )}
    <button type="submit" className="ml-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700">
      Save
    </button>
  </form>
</Modal>

            </div>

          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">No candidates found for this job.</p>
      )}
    </div>
  );
};

export default CandidateListPage;
