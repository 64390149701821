import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from './useAuth'; // Import AuthProvider
import SignIn from './SignIn';
import SignUp from './SignUp';
import JobPostingsPage from "./pages/JobPostingsPage";
import CandidateListPage from './pages/CandidateListPage'; // Make sure you have this component created
import ApplicationsPage from './pages/ApplicationsPage'; // Import your ApplicationsPage component
import ThankYouPage from './pages/ThankYouPage';


function App() {
  return (
    <AuthProvider>
    <Router>
      <Routes>
          <Route path="/signin" element={<SignIn/>} />
          <Route path="/signup" element={<SignUp/>} />
        <Route path="/job-postings" element={<JobPostingsPage />} />
        <Route path="/candidates/:jobID/tenants/:tenantID" element={<CandidateListPage />} />
      <Route path="/applications/:jobID/tenants/:tenantID" element={<ApplicationsPage />} />
      <Route path="/thank-you" element={<ThankYouPage />} />

        {/* Define other routes */}
      </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;
