import { Link, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
// import jobPostingsData from '../data/jobPostings.json'; // Path to your JSON data
import Modal from '../components/Modal'; // Ensure you have this component
import { signOut } from 'firebase/auth';
import { auth } from '../firebase-config';
import { useAuth } from '../useAuth';


  

const JobPostingsPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [jobPostingsData, setJobPostingsData] = useState();
    const [tenantID, setTenantID] = useState();
    const [userInfo, setUserInfo] = useState();
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [currentJobPosting, setCurrentJobPosting] = useState(); 


    const { currentUser } = useAuth();

    useEffect(() => {
      currentUser? fetch(`https://interview-412415.ue.r.appspot.com/api/v1/users/${currentUser.uid}`, {
        method: "GET",
        headers: {
          "X-RapidAPI-Key": "your-api-key",
          "X-RapidAPI-Host": "jokes-by-api-ninjas.p.rapidapi.com",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setTenantID(data.user.tenantId);
          setUserInfo(data.user)
          console.log(data);
        })
        .catch((error) => console.log(error)):handleSignin();
    }, [currentUser]);

    useEffect(() => {
      tenantID? fetch(`https://interview-412415.ue.r.appspot.com/api/v1/tenants/${tenantID}/jobpostings`, {
        method: "GET",
        headers: {
          "X-RapidAPI-Key": "your-api-key",
          "X-RapidAPI-Host": "jokes-by-api-ninjas.p.rapidapi.com",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setJobPostingsData(data.user);
          console.log(data);
        })
        .catch((error) => console.log(error)):setTenantID(userInfo? userInfo.tenantId:"")
    }, [tenantID, userInfo]);

      


    console.log(`currentUser ${JSON.stringify(currentUser)}`)
    console.log(`userInfo ${JSON.stringify(userInfo)}`)

    const signout = async (event) => {
      event.preventDefault();
      console.log(`event: ${event}`)
      signOut(auth).then((i) => {
      // Sign-out successful.
    console.log(`Sign-out successful. ${i} \ncurrentUser ${JSON.stringify(currentUser)}`)
    }).catch((error) => {
      // An error happened.
    console.log(`Sign-out failed. currentUser ${JSON.stringify(currentUser)}, ${error}`)
    });
  }
  const handleSignin = () => {
    return <Navigate to="/signin" replace />;
  };

    if (!currentUser) {
      return <Navigate to="/signin" replace />;
    }
    
    console.log(`currentUser tenant ${tenantID}`)

// Function to open the modal for a specific candidate
const handleOpenStatusModal = (candidateId) => {
  setCurrentJobPosting(jobPostingsData.find(jobPostingsData => jobPostingsData.uuid === candidateId));
  setIsStatusModalOpen(true);
};

// Function to update candidate status (simplified, update your state accordingly)
const handleUpdateCandidateStatus = (candidateId, newStatus) => {
  var c = currentJobPosting
  c.Statut = newStatus;
  setCurrentJobPosting(c)
  setJobPostingsData(jobPostingsData.map(candidate => 
    candidate.uuid === candidateId ? { ...candidate, Statut: newStatus } : candidate
  ));
  console.log(`Before PUT: ${currentJobPosting.Statut} - ${newStatus}`)
  fetch(`https://interview-412415.ue.r.appspot.com/api/v1/tenants/${tenantID}/jobpostings/${currentJobPosting.uuid}`, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(currentJobPosting),
  })
    .then((response) => response.json())
    .then((data) => {
      // setJobPostingsData(data.user);
      console.log(`PUT: ${JSON.stringify(data)}`);
    })
    .catch((error) => console.log(error));
  setIsStatusModalOpen(false); // Assuming you wish to close the modal after updating
};

  // Function to toggle the create new job modal
  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };

    const handleJobClick = (job) => {
        setSelectedJob(job);
        setIsModalOpen(true);
      };
      const handleCreateJobSubmit = async (event) => {
        event.preventDefault();
      
        // Assuming you're using form elements with names corresponding to your job posting object properties
        const formData = new FormData(event.target);
        const newJobData = Object.fromEntries(formData.entries());
      
        // Here we convert form data to the required JSON structure
        // Adjust this as necessary based on your backend expectations and form structure
        const jobPosting = {
          JobID: newJobData.JobID, // Assuming you have a way to generate or input this
          Titre: newJobData.Titre,
          Entreprise: newJobData.Entreprise,
          Lieu: newJobData.Lieu,
          TypeDePoste: newJobData.TypeDePoste,
          Secteur: newJobData.Secteur,
          CategorieDeRole: newJobData.CategorieDeRole,
          Description: newJobData.Description,
          Qualifications: newJobData.Qualifications ? newJobData.Qualifications.split(','):[], // Assuming comma-separated input for arrays
          NiveauDExperience: newJobData.NiveauDExperience,
          CompetencesRequises: newJobData.CompetencesRequises ? newJobData.CompetencesRequises.split(','):[],
          FourchetteDeSalaire: newJobData.FourchetteDeSalaire,
          Avantages: newJobData.Avantages ? newJobData.Avantages.split(','):[],
          DateLimiteDeCandidature: newJobData.DateLimiteDeCandidature,
          ProcedureDeCandidature: newJobData.ProcedureDeCandidature,
          InformationsDeContact: {
            NomDuContactRH: newJobData.NomDuContactRH,
            Email: newJobData.Email,
            Telephone: newJobData.Telephone
          },
          CreatedBy: {
            fullName: userInfo.fullName,
            email: userInfo.email,
          },
          // createdOn: Time.Now(),
          DateDePublication: newJobData.DateDePublication,
          Statut: "Submissions",
          LogoDeLentreprise: newJobData.LogoDeLentreprise,
          DescriptionDeLentreprise: newJobData.DescriptionDeLentreprise,
          DeclarationDeDiversite: newJobData.DeclarationDeDiversite,
        };
      
        /* try {
          const response = await fetch('/api/jobPostings', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(jobPosting),
          });
      
          if (!response.ok) throw new Error('Network response was not ok.');
      
          // Handle success
          console.log('Job posting added successfully.');
          setIsCreateModalOpen(false); // Close the modal
          alert('Job posting added successfully.');
          // Optionally, you might want to refresh the job postings list here
        } catch (error) {
          console.error('Failed to add job posting:', error);
        } */
        fetch(`https://interview-412415.ue.r.appspot.com/api/v1/tenants/${tenantID}/jobpostings`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jobPosting),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(`Job posting added successfully: ${JSON.stringify(data.user)}`);
          setIsCreateModalOpen(false); // Close the modal
          alert('Job posting added successfully.');
        })
        .catch((error) => console.log(error));
      };
      
      
    
  return (
    <div className="bg-african-sand p-6 rounded-lg shadow-md">
      <button
        onClick={signout}
        className="absolute top-0 right-0 m-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Sign Out
      </button>
        <button
        onClick={toggleCreateModal}
        className="absolute top-0 right-100 m-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Create New Job
      </button>

      <Modal isOpen={isCreateModalOpen} onClose={toggleCreateModal} title="Create New Job Posting">
  <form onSubmit={handleCreateJobSubmit} className="space-y-4">
    <div>
      <label className="block text-sm font-medium text-gray-700">Titre</label>
      <input name="Titre" type="text" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>
    
    
    <div>
      <label className="block text-sm font-medium text-gray-700">Entreprise</label>
      <input name="Entreprise" type="text" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Description De L'entreprise</label>
      <textarea name="DescriptionDeLentreprise" type="text" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" ></textarea>
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700">Lieu</label>
      <input name="Lieu" type="text" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>
    
    <div>
      <label className="block text-sm font-medium text-gray-700">Date De Publication</label>
      <input name="DateDePublication" type="date" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700">Nom Du Contact RH</label>
      <input name="NomDuContactRH" type="text" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Email du RH</label>
      <input name="Email" type="email" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Telephone du RH</label>
      <input name="Telephone" type="tel" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>
    <div>
  <label className="block text-sm font-medium text-gray-700">Type de Poste</label>
  <select name="TypeDePoste" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md">
    <option value="">Select Type de Poste</option> {/* Placeholder option */}
    <option value="Temps plein">Temps plein</option>
    <option value="Temps partiel">Temps partiel</option>
    <option value="Contrat">Contrat</option>
    <option value="Stage">Stage</option>
    <option value="Freelance">Freelance</option>
  </select>
</div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Secteur</label>
      <input name="Secteur" type="text" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>
<div>
      <label className="block text-sm font-medium text-gray-700">Date Limite De Candidature</label>
      <input name="DateLimiteDeCandidature" type="date" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Secteur</label>
      <input name="Secteur" type="text" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700">Categorie de Role</label>
      <input name="CategorieDeRole" type="text" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md" />
    </div>
<div>
  <label className="block text-sm font-medium text-gray-700">Niveau d'Experience</label>
  <select name="TypeDePoste" required className="mt-1 block w-md rounded-md border-black-300 shadow-md">
    <option value="">Choisir le Niveau d'Experience</option> {/* Placeholder option */}
    <option value="Stage">Stage</option>
    <option value="Entree">Entree</option>
    <option value="Mid">Mid</option>
    <option value="Senior">Senior</option>
    <option value="Manager">Manager</option>
    <option value="Directeur">Directeur</option>
    <option value="Executif">Executif</option>
  </select>
</div>

    <div>
      <label className="block text-sm font-medium text-gray-700">Description</label>
      <textarea name="Description" required className="mt-1 block w-full rounded-md border-gray-700 shadow-md"></textarea>
    </div>

    {/* For arrays like Qualifications, CompetencesRequises, and Avantages, you can use textareas or a series of inputs */}
    <div>
      <label className="block text-sm font-medium text-gray-700">Qualifications (comma-separated)</label>
      <textarea name="Qualifications" required className="mt-1 block w-full rounded-md border-gray-300 shadow-md"></textarea>
    </div>

    {/* Add other fields as necessary... */}

    <div>
      <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
        Submit
      </button>
    </div>
  </form>
</Modal>


      <h1 className="text-4xl font-bold text-center mb-10 text-blue-800">Job Postings</h1>
      <div className="space-y-8">
        {jobPostingsData ? jobPostingsData.map((posting, index) => (
          <section key={posting.id} className={`${index !== 0 ? 'pt-6' : ''} bg-blue-50 shadow-md rounded-lg overflow-hidden`}>
            {index !== 0 && <hr className="border-t border-blue-200" />}
            <div className="p-6">
            
              <h2 className="text-2xl font-bold mb-2 text-blue-700" onClick={() => handleJobClick(posting)}>{posting.Titre}{"  -  "} {posting.JobID}</h2>
              <h3 className="text-md text-blue-600">{posting.Entreprise} - {posting.Lieu}</h3>
              <h3 className="text-md text-blue-600">localhost:3000/applications/{posting.uuid}/tenants/{tenantID}</h3>
              
              <p className={`text-gray-600 ${posting.Statut === 'Offres' ? 'text-green-600' : ''}`}>Statut: {posting.Statut} - {  }
              <button onClick={() => handleOpenStatusModal(posting.uuid)} className="px-4 py-2 bg-blue-400 text-white rounded hover:bg-blue-700">
          Changer Statut
        </button></p>
        <Modal isOpen={isStatusModalOpen} onClose={() => setIsStatusModalOpen(false)} title="Changer Le Statut Du Poste">
  <form onSubmit={(e) => {
   e.preventDefault();
   const newStatus = e.target.status.value; // Assuming the select has a name attribute of 'status'
   
   // Ensure `currentCandidate` is set up to track the candidate being edited
   if (currentJobPosting) {
     handleUpdateCandidateStatus(currentJobPosting.uuid, newStatus);
   }

   setSelectedStatus(''); // Reset the selected status if needed
  }}>
    <select 
      name="status" 
      className="border-gray-300 rounded" 
      value={selectedStatus} 
      onChange={(e) => setSelectedStatus(e.target.value)}
    >
      <option value="">Choisir Statut</option>
      <option value="Submissions">Soumission des candidatures</option>
      <option value="Evaluations">Soumission des tests d'evaluation</option>
      <option value="Entretients">Entretients</option>
      <option value="Offres">Offre d'emploi</option>
      <option value="Complet">Complet</option>
      <option value="Pause">Pause</option>
      <option value="Annuler">Annuler</option>
      {/* Add other statuses as needed */}
    </select>
    {selectedStatus && (
      <p className="text-red-500">Statut: {selectedStatus}. Veillez confirmer.</p>
    )}
    <button type="submit" className="ml-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700">
      Save
    </button>
  </form>
</Modal>
              <p className="text-gray-600 mt-4">{posting.Description}</p>
              <Link to={`/candidates/${posting.uuid}/tenants/${tenantID}`} className="text-blue-500 hover:underline">View Candidates</Link>
            </div>
          </section>
        )):""}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {selectedJob && (
          <div>
            <h2 className="text-xl font-bold">{selectedJob.Titre} ({selectedJob.JobID})</h2>
            {/* Render all details of the selected job */}
            <p><strong>Entreprise:</strong> {selectedJob.Entreprise}</p>
            <p><strong>Lieu:</strong> {selectedJob.Lieu}</p>
            <p><strong>Description:</strong> {selectedJob.Description}</p>
            <p><strong>Qualifications:</strong></p>
            <ul>
              {selectedJob.Qualifications.map((qual, index) => (
                <li key={index}>{qual}</li>
              ))}
            </ul>
            {/* Continue adding other details like CompetencesRequises, Avantages, etc. */}
            <div>
              <strong>Comment Postuler:</strong> {selectedJob.ProcedureDeCandidature}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default JobPostingsPage;
