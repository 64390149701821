import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import jobPostingsData from '../data/jobPostings.json'; // Path to your JSON data
import { Country, State, City }  from 'country-state-city';
import { AsYouType } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
// const {Storage} = require('@google-cloud/storage');


const ApplicationsPage = () => {
  const navigate = useNavigate();
  const { jobID, tenantID } = useParams();
  const [jobDetails, setJobDetails] = useState({});
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [diplomas, setDiplomas] = useState([]);  
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [educations, setEducations] = useState([{ Institution: '', Degree: '', FieldOfStudy: '', GraduationYear: '' }]);
//   const [workExperience, setWorkExperience] = useState([{ CompanyName: '', Role: '', StartDate: '', EndDate: '', Responsibilities: '' }]);
  const [references, setReferences] = useState([{ RefereeName: '', Relation: '', ContactInformation: '' }]);
  const [certifications, setCertifications] = useState([{ CertificationName: '', IssuingOrganization: '', DateObtained: '', ExpiryDate: '' }]);
  const [languages, setLanguages] = useState([{ Language: '', Proficiency: '' }]);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [educationErrors, setEducationErrors] = useState([{ Institution: false, Degree: false, FieldOfStudy: false, GraduationYear: false }]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [resumeURL, setResumeURL] = useState("");


  useEffect(() => {
    fetch(`https://interview-412415.ue.r.appspot.com/api/v1/tenants/KEO-Tech-6380h/jobpostings/${jobID}`, {
      method: "GET",
      headers: {
        "X-RapidAPI-Key": "your-api-key",
        "X-RapidAPI-Host": "jokes-by-api-ninjas.p.rapidapi.com",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setJobDetails(data.user);
        console.log(JSON.stringify(data));
      })
      .catch((error) => console.log(error));
  }, [jobID]);


/*   useEffect(() => {
    const job = jobPostingsData.find(job => job.JobID === jobID);
    setJobDetails(job || {});
  }, [jobID]); */
  const handleFileUpload = async (f, location, filename) => {
    // console.log(`f type: ${typeof(f)} - f.data type: ${typeof(f.data)}`)
    const formData  = new FormData();
    for(const name in f) {
      formData.append(name, f[name]);
    }
    fetch(`https://interview-412415.ue.r.appspot.com/storage/${location}/name/${filename}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // setJobDetails(data.user);
        console.log(JSON.stringify(data));
        setResumeURL(data.publicUrl)
        return data;
      })
      .catch((error) => {
        alert(`Une erreur s'est produite en téléchargent votre CV. Veuillez réessayer`)
        throw error()});
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedState(''); // Reset state when country changes
    setSelectedCity('');
  };

  const handlePhoneNumberChange = (event) => {
    const asYouType = new AsYouType(selectedCountry);
    setPhoneNumber(asYouType.input(event.target.value));
  };


//****Reference Methods */
  const addNewReferences = () => {
    setReferences([...references, { RefereeName: '', Relation: '', ContactInformation: '' }]);
  };
  const removeReference = (index) => {
    const filteredReferences = [...references];
    filteredReferences.splice(index, 1);
    setReferences(filteredReferences);
  };
  const handleReferenceChange = (index, e) => {
    const updatedReferences = [...references];
    updatedReferences[index][e.target.name] = e.target.value;
    setReferences(updatedReferences);
  };

  //Certifications methods
  const handleCertificationChange = (index, e) => {
    const updatedCertifications = [...certifications];
    updatedCertifications[index][e.target.name] = e.target.value;
    setCertifications(updatedCertifications);
  };
  
  const addNewCertification = () => {
    setCertifications([...certifications, { CertificationName: '', IssuingOrganization: '', DateObtained: '', ExpiryDate: '' }]);
  };
  
  const removeCertification = (index) => {
    const filteredCertifications = [...certifications];
    filteredCertifications.splice(index, 1);
    setCertifications(filteredCertifications);
  };
  
  
  const handleLanguageChange = (index, e) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index][e.target.name] = e.target.value;
    setLanguages(updatedLanguages);
  };
  
  const addNewLanguage = () => {
    setLanguages([...languages, { Language: '', Proficiency: '' }]);
  };
  
  const removeLanguage = (index) => {
    const filteredLanguages = [...languages];
    filteredLanguages.splice(index, 1);
    setLanguages(filteredLanguages);
  };


  const addNewEducation = () => {
    setEducations([...educations, { Institution: '', Degree: '', FieldOfStudy: '', GraduationYear: '' }]);
  };
  const removeEducation = (index) => {
    const filteredEducations = [...educations];
    filteredEducations.splice(index, 1);
    setEducations(filteredEducations);
  };
  const handleEducationChange = (index, e) => {
    const updatedEducations = [...educations];
    updatedEducations[index][e.target.name] = e.target.value;
    setEducations(updatedEducations);
  };
  
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    if (name === 'certificates') {
      setCertificates([...files]);
    } else if (name === 'diplomas') {
        setDiplomas([...files]);
      } else if (name === 'resume') {
        setResume(files[0]);
      }else{
      setCoverLetter(files[0]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    const newJobData = Object.fromEntries(formData.entries());
    setSubmissionAttempted(true); // Indicate a submission attempt has been made
    console.log(`newJobData: ${JSON.stringify(newJobData)}`)
    console.log(`event: ${event.target}`)

  const newEducationErrors = educations.map(edu => ({
    Institution: !edu.Institution,
    Degree: !edu.Degree,
    FieldOfStudy: !edu.FieldOfStudy,
    GraduationYear: !edu.GraduationYear,
  }));

  setEducationErrors(newEducationErrors);

  // Check if any education entry is invalid
  const isValid = !newEducationErrors.some(err => Object.values(err).includes(true));

  if (!isValid) {
    alert("Please fill in all required fields.");
    return; // Prevent form submission if validation fails
  }

     // Check if at least one education entry is fully filled
  const isValidEducation = educations.some(edu => edu.Institution && edu.Degree && edu.FieldOfStudy && edu.GraduationYear);

  if (!isValidEducation) {
    alert("Veillez replir votre Education.");
    return; // Prevent form submission
  }

  if (diplomas == null) {
    alert("Veillez ajouter un diplome.");
    return; // Prevent form submission
  }
  
  if (resume == null) {
    alert("Veillez ajouter votre CV.", resume);
    return; // Prevent form submission
  }
  
  for (let file of certificates) {
    formData.append('certificates', file);
  }
  for (let file of diplomas) {
    formData.append('diplomas', file);
  }
  console.log(`type for resme: ${typeof(resume)} ${JSON.stringify(resume)}`)
  /* if (resume.lenght > 0){
    handleFileUpload(resume).catch(e => {console.log(`GCP Upload error: ${e}`)})
  } */
 handleFileUpload(resume, "resumeactive", `Resume_${fullName.replace(/\s/g, '')}`).catch(e => {alert(`erreur upload`)
return}).then(r => setResumeURL(r.publicUrl))
console.log(`publicUrl: ${resumeURL}`)
  
  const jobPosting = {
    ApplicantID: "559988",
    FullName: fullName,
    Score: null,
    ScoreReason: "",
    ContactInformation: {
        Email: email,
        Phone: phoneNumber,
        Address: address,
        City: selectedCity,
        State: selectedState,
        Country: selectedCountry
    },
    PositionAppliedFor: jobDetails.Titre,
    ApplicationDate: new Date().toDateString(),
    Diploma: diplomas,
    Resume: resumeURL,
    CoverLetter: coverLetter,
    Education: educations,
    Skills: newJobData.Skills,
    References: references,
    Portfolio: "https://www.exemple.fr/portfolio/honore_kodjo",
    Certifications: certifications,
    Languages: languages,
    ApplicationStatus: "Soummise"
  };

    fetch(`https://interview-412415.ue.r.appspot.com/api/v1/tenants/${tenantID}/jobpostings/${jobID}/applications`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jobPosting),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      navigate('/thank-you');
    })
    .catch((error) => {console.log(error)
    alert(error)});
    
}

console.log(jobDetails ? jobDetails.DateLimiteDeCandidature:"nothing")
var today = new Date().getTime();
let date1 = jobDetails ? new Date(jobDetails.DateLimiteDeCandidature).getTime():today;
console.log(jobDetails ? jobDetails.DateLimiteDeCandidature:"nothing", today, date1)
if (date1 < today){
      return(
        <div className="bg-african-sand p-6 rounded-lg shadow-md">Desole le date limite de cette candidature est passee.</div>
      )
}else{
  return (
    
    <div className="bg-african-sand p-6 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Application for {jobDetails.Titre || 'Job'}</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input type="hidden" name="JobID" value={jobID} readOnly />
        <div>
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
          <input name="FullName" id="fullName" type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} required className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input name="Email" id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" />
        </div>

             
      <div>
          <label htmlFor="address" className="block text-sm font-medium text-gray-700">Addresse</label>
          <input name="Address" id="address" type="text" value={address} onChange={(e) => setAddress(e.target.value)} required className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" />
        </div>
        
        <div>
          <label>Country</label>
          <select onChange={handleCountryChange} required value={selectedCountry}>
            <option value="">Select Country</option>
            {Country.getAllCountries().map((country) => (
              <option key={country.isoCode} value={country.isoCode}>{country.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>State/Region</label>
          <select onChange={(e) => setSelectedState(e.target.value)} value={selectedState}>
            <option value="">Select State</option>
            {State.getStatesOfCountry(selectedCountry).map((state) => (
              <option key={state.isoCode} value={state.isoCode}>{state.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Ville</label>
          <select onChange={(e) => setSelectedCity(e.target.value)} value={selectedCity}>
            <option value="">Choisi la ville</option>
            {City.getCitiesOfCountry(selectedCountry).map((city) => (
              <option key={city.stateCode} value={city.stateCode}>{city.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Phone Number</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Enter phone number"
          />
        </div>

        <div>
          <label htmlFor="resume" className="block text-sm font-medium text-gray-700">CV</label>
          <input name="resume" id="resume" type="file" required className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"  onChange={handleFileChange}/>
        </div>

        <div>
          <label htmlFor="coverLetter" className="block text-sm font-medium text-gray-700">Lettre de Motivation</label>
          <input name="CoverLetter" id="coverLetter" type="file" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"  onChange={handleFileChange}/>
        </div>
         {/* Education section */}
         <div className="space-y-4">
          <h2 className="text-xl font-semibold mb-4">Education</h2>
          {educations.map((edu, index) => (
            <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input className={`input ${submissionAttempted && educationErrors[index].Institution ? 'border-red-500' : ''}`} placeholder="Institution" type="text" name={`Institution`} value={edu.Institution} onChange={(e) => handleEducationChange(index, e)} />
              <input className={`input ${submissionAttempted && educationErrors[index].Degree ? 'border-red-500' : ''}`} placeholder="Degree" type="text" name={`Degree`} value={edu.Degree} onChange={(e) => handleEducationChange(index, e)} />
              <input className={`input ${submissionAttempted && educationErrors[index].FieldOfStudy ? 'border-red-500' : ''}`} placeholder="Field of Study" type="text" name={`FieldOfStudy`} value={edu.FieldOfStudy} onChange={(e) => handleEducationChange(index, e)} />
              <input className={`input ${submissionAttempted && educationErrors[index].GraduationYear ? 'border-red-500' : ''}`} placeholder="Graduation Year" type="month" name={`GraduationYear`} value={edu.GraduationYear} onChange={(e) => handleEducationChange(index, e)} />
              <button
        type="button"
        onClick={() => removeEducation(index)}
        className="col-span-2 md:col-span-1 text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2"
      >
        Effacer une education
      </button>
            </div>
          ))}
          <button type="button" onClick={addNewEducation} className="mt-2 text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Add Another Education</button>
        </div>
  
<div>
          <h2 htmlFor="diplomas" className="text-xl font-semibold mb-4">Diplomes</h2>
          <input type="file" id="diplomas" name="diplomas" multiple onChange={handleFileChange} />
        </div>



   {/* references section */}
   <div className="space-y-4">
  <h2 className="text-xl font-semibold mb-4">References</h2>
  {references.map((reference, index) => (
    <div key={index} className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
      <input
        className="input"
        name="RefereeName"
        placeholder="Referee Name"
        type="text"
        value={reference.RefereeName}
        onChange={(e) => handleReferenceChange(index, e)}
      />
      <input
        className="input"
        name="Relation"
        placeholder="Relation"
        type="text"
        value={reference.Relation}
        onChange={(e) => handleReferenceChange(index, e)}
      />
      <input
        className="input"
        name="ContactInformation"
        placeholder="Contact Information"
        type="text"
        value={reference.ContactInformation}
        onChange={(e) => handleReferenceChange(index, e)}
      />
      <button
        type="button"
        onClick={() => removeReference(index)}
        className="col-span-3 md:col-span-1 text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2"
      >
        Effacer une reference
      </button>
    </div>
  ))}
  <button
    type="button"
    onClick={addNewReferences}
    className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
  >
    Ajouter une reference
  </button>
</div>




   {/* certifications section */}
   <div className="space-y-4">
  <h2 className="text-xl font-semibold mb-4">Certifications</h2>
  {certifications.map((cert, index) => (
    <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
      <input
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        name="CertificationName"
        placeholder="Certification Name"
        type="text"
        value={cert.CertificationName}
        onChange={(e) => handleCertificationChange(index, e)}
      />
      <input
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        name="IssuingOrganization"
        placeholder="Issuing Organization"
        type="text"
        value={cert.IssuingOrganization}
        onChange={(e) => handleCertificationChange(index, e)}
      />
      <input
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        name="DateObtained"
        placeholder="Date Obtained (YYYY-MM-DD)"
        type="date"
        value={cert.DateObtained}
        onChange={(e) => handleCertificationChange(index, e)}
      />
      <input
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        name="ExpiryDate"
        placeholder="Expiry Date (YYYY-MM-DD)"
        type="date"
        value={cert.ExpiryDate}
        onChange={(e) => handleCertificationChange(index, e)}
      />
      <button
        type="button"
        onClick={() => removeCertification(index)}
        className="col-span-2 md:col-span-1 text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2"
      >
        Remove
      </button>
    </div>
  ))}
  <button
    type="button"
    onClick={addNewCertification}
    className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
  >
    Add Another Certification
  </button>
</div>


        <div>
          <label htmlFor="certificates" className="block text-sm font-medium text-gray-700">Certificats</label>
          <input type="file" id="certificates" name="certificates" multiple onChange={handleFileChange} />
        </div>
        

   {/* Education section */}
   <div className="space-y-4">
  <h2 className="text-xl font-semibold mb-4">Languages</h2>
  {languages.map((language, index) => (
    <div key={index} className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
      <input
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        name="Language"
        placeholder="Language"
        type="text"
        value={language.Language}
        onChange={(e) => handleLanguageChange(index, e)}
      />
      <select
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        name="Proficiency"
        value={language.Proficiency}
        onChange={(e) => handleLanguageChange(index, e)}
      >
        <option value="">Select Proficiency</option>
        <option value="Basic">Basic</option>
        <option value="Conversational">Conversational</option>
        <option value="Fluent">Fluent</option>
        <option value="Native">Native</option>
      </select>
      <button
        type="button"
        onClick={() => removeLanguage(index)}
        className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Remove
      </button>
    </div>
  ))}
  <button
    type="button"
    onClick={addNewLanguage}
    className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
  >
    Add Another Language
  </button>
</div>

        
        <div>
          <label htmlFor="qualifications" className="block text-sm font-medium text-gray-700">Qualifications</label>
          <textarea name="Qualifications" id="qualifications" rows="4" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"></textarea>
        </div>

        <div>
          <label htmlFor="skills" className="block text-sm font-medium text-gray-700">Skills</label>
          <input name="Skills" id="skills" type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" placeholder="Comma-separated, e.g., HTML, CSS, JavaScript"/>
        </div>
        

        {/* Add more fields as necessary for additional data you wish to collect */}

        <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
          Submit Application
        </button>
      </form>
    </div>
  );
}
};

export default ApplicationsPage;
