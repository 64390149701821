import React from 'react';

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 z-50 flex justify-center items-start overflow-y-auto pt-10">
      <div className="bg-white p-5 rounded-lg max-w-3xl w-full mx-4 my-10 relative">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <button className="absolute top-0 right-0 mt-2 mr-2 text-lg font-bold" onClick={onClose}>×</button>
        <div className="overflow-y-auto max-h-[80vh]">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
