import React from 'react';

const ThankYouPage = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 text-center">
      <h1 className="text-3xl font-bold mb-4">Thank You for Your Submission!</h1>
      <p>We have received your application and will get back to you shortly.</p>
    </div>
  );
};

export default ThankYouPage;
