// src/firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDEx-AWbcEzddBpppEghNiFGGHK4SN--PI",
    authDomain: "interview-412415.firebaseapp.com",
    projectId: "interview-412415",
    storageBucket: "interview-412415.appspot.com",
    messagingSenderId: "238410973313",
    appId: "1:238410973313:web:b77fdb59818cd51969cd80",
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth };
